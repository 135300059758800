@media (max-width: 576px) {
    .custom-toggler {
        display: none;
    }

    .custom-toggler-second {
        display: block;
    }
}


@media (min-width: 580px) {
    .custom-toggler {
        display: block;
    }

    .custom-toggler-second {
        display: none;
    }
}


