.cardStyle {
  margin: 20px;
}

.tableHeading {
  text-align: center;
  border: 1px solid grey;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  padding-left: 30%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.firstRow {
  width: 50%;
  padding: 12px;
  font-size: 18px;
  color: #808488;
}

.backgorundGray {
  background: #f0f3f5;
}

.fontSizeBold {
  font-weight: bold;
}

.fontSize {
  font-size: 18px;
  background: #f0f3f5;
}

.paddingLeft {
  padding-left: 20px;
}

.secondTableHeading {
  text-align: center;
  border: 1px solid grey;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0;
}

.button {
  padding: 10px;
  width: 150px;
  background-color: #3c4b64;
  color: white;
  font-size: 18px;
}

.button:hover {
  background-color: #3c4b64;
}

.text-right {
  text-align: right;
}
